
@use 'font.scss';
@use 'layout.scss';
@use 'animation.scss';
@use 'jsfonction.scss';
@use 'mobile.scss';

html {
  box-sizing: border-box;
  font-size: 16px;
  background-color: #121212;

}

body{
  margin: 0 20px;  
}
.nav__btn{
  padding: 5px 40px;
  font-weight: 900;
  border: 2px solid #fff;
  color: #fff;
  background-color: transparent;
  font-family: 'Roboto Mono', monospace;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 13%;
  height:54px;
  
&--fleche{
  white-space: nowrap;
  text-indent: 100%;
  overflow: hidden;
  width: 54px;
  padding: 0;
  border: 0;
  background-color: wheat;
  margin:0 20px  
}}

.container__circle{
  height: 260px;
  width: 260px;
  position: relative;
  
  margin:  auto;
  
&--rep{
  position: relative;
  
  
}}
.circle{
  fill: white;
  opacity: 70%;
}
.cta3{
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  position: absolute;
  cursor: pointer;
 height: 55px;
 width: 55px;
 border-radius: 50%;
 border: 1px solid #FF0000;
 background-color: #FF0000;
 color: white;
 z-index: 1;
 padding: 0;
 margin: 0;
}

.head{
  height: 150px;
  &__lien{
    display: block;
    position: fixed;
    width: 142px;
    height: 25px;
    text-align: center;
    z-index: 99999;
    padding: 15px 20px ;
    font-weight: 900;
    border: 2px solid #fff;
    color: #fff;
    text-decoration: none;
    top: 33;
    right: 20px;
  }
  &__lien:hover{
    background-color: #fff;
    transition: 0.6s;
    color: #121212;
  }
  &--ecart{
    margin: 10vh 0 100px 0;
  }
  
}

.sect{
  height: 270vh;
  &--body{
    height: 380vh;
  }
  &--position{
    position: relative;
    top: -800px;
    height: 300vh;
  }
  &--ecart{
    margin: 400px 50px;
  }
  &--titrePlacement{
    position: relative;
    top: 70;
    /*left: 212;*/ 
    left: 2vw;
    height: 80vh;
    width: 80vw;
  }
  &__boxElement{
    height: 450px;

  }
  &__box{
   /* &--2{
      margin: 120px 0 0 0;
      width: 680px;
      height: 680px;
    }*/
    &--3{
      display: flex;
      flex-direction: row;
      position: sticky;
     /* right: -200 ;
      left: 60vw;*/
      overflow: hidden;
      margin: 0 0 0 2vw;
      top: 20;
      bottom: 60vh;
      z-index: -2;
    }
  }
  &__list{
    
    list-style: none;
  }
  &__cercle{
    &--p{
      width: 100px;
      height: 100px;
    }
    &--m{
      width: 250px;
      height: 250px;
    }
    &--g{
      width: 350px;
      height: 350px;
    }
    &--ecart{
      margin: 0 0 80px 0;
    }
  }
  &__element{
    position: relative;
    margin: 0vh 0 10vh 0;
    top: -10vh;
    left: 10vw;
  }
  &__lien{
    cursor: url(../images/fuck.png) 0 0, auto !important;
  }
  &--défilement{
    width: 100vw;
    overflow: hidden;
    margin: 0 0 81px 0;
  }
  /*&__btn{
    padding:30px 30px 30px 30px;
    background-color: #FF0000;
    border-radius: 60px;
    height: 30px;
    width: 30px;
    &--2{
      padding: 20px 60px;
      border: 2px solid #fff;
      width: 120px;
      text-align: center;
      margin: 80px 0 0 30px;
    }
    &--2:hover{
      background-color: #fff;
      
      color: #121212;
      cursor: pointer;
      
    }
    &--place{
      position: relative;
      z-index:100;
      top: -370;
      left: 42%;
      display: flex;
      align-items: center;
      align-content: center;
    }
  }
  &__btn:hover{
    background-color: #FF0000a5;
     
  }*/
  /*&__img{
    &--first{
      position: relative;
      
    }
    &--2{
      height: 30px;
      width: 30px;
    }
    &--place{
      position: relative;
      z-index: 1;
    }
    &--place2{
      position: absolute;
    }
  }*/
}

.lien{
  &--position{
    position: relative;
    top: -120;
    left: 30;
  }
  &__list{
    list-style: none;
  }
  &__element{
    width: 160px;
    cursor: pointer;
  }
  &__element:hover{
    background-color: red;
  }
  &__lien{
    color: #fff;
    padding: 10px 20px;
    width: 160px;
    font-size: 18px;
    font-family: 'Roboto Mono', monospace;
    text-decoration: none;
    font-weight: 300;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
  }
  
}
.foot{
  margin: 280px 0 0 0;
  &--flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__list{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__lien{
    &--start{
      text-align: start;
    }
  }
}
.box{
  &__cercle{
    opacity: 0.05;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    height: 700px;
    &--first{
      margin: 200px -40 0 0;
    }
  }
}
@media(min-width:600px){
  .head{
    &__lien{
      top: 33;
      right: 66;
      left: auto;
      padding: 22px 49px ;
      }
    }
  .sect{
      &--titrePlacement{
        left: 5vw;
        height: 80vh;
      }
      &__box{
        &--3{
          overflow: visible;
          margin: 0 0 5vw 0;
          top: 100;
        }
      }
      &__element{
        position: relative;
        margin: 6vh 0 0 0;
        top: 0;
        left: 0;
      }
  }

}
@media(min-width:1024px){
  .head{
    &__lien{
      top: 40;
      right: 80;
      left: auto;
      padding: 22px 49px ;
      }
    }
    .sect{
      &--titrePlacement{
        left: 5vw;
        right: 20;
        height: 80vh;
      }
      &__box{
        &--3{
          overflow: visible;
          margin: 0 0 0 60vw;
          top: 20;
        }
      }
      &__cercle{
        &--p{
          width: 270px;
          height: 270px;
        }
        &--m{
          width: 350px;
          height: 350px;
        }
        &--g{
          width: 450px;
          height: 450px;
        }
        &--ecart{
          margin: 0 0 80px 0;
        }
      }
  }
}
.btndark a{
  color: red;
  opacity: 70%;
}
.pource {
  fill: red;
}
.pourceWhite{
  fill: #fffffa30;
}
.active{
  background-color: red;
  padding: 10px 20px;
  width: 140px;
}