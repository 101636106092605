.titre--position{
    display:flex;
    align-content:center;
}

.nav{
    
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    left:0px;
    background-color: #121212e6;
    
    width: 100vw;
    display: flex;
    justify-content: space-between;


}
.circle{
    height: 200px;
    width: 200px;
    &1{
        position:absolute;
        top:0;
        right: 0;
    }
    &2{
        position:absolute;
        top:0;
        left: 0;
}
    &3{
        position: relative;
        top: 20;
        right: 30;
        fill: red;
        margin: 12vh 0 0 0;
    }
   /* &--marge{
        margin: 12vh 0 0 0 ;
    }*/
}

.question{
    margin-top: 60px;
    max-width: 335px;
    margin-bottom: 80px;
}

.sect{
    &__list{
        display: flex;
        flex-direction: row;
    }
    &--col{
        display: flex;
        flex-direction: column;
        
    }
}


.b1{
    grid-area: b1;
}
.b2{
    grid-area: b2;
}
.b3{
    grid-area: b3;
}
.b4{
    grid-area: b4;
}
.b5{
    grid-area: b5;
}
.b6{
    grid-area: b6;
}
.b7{
    grid-area: b7;
}
.b8{
    grid-area: b8;
}

/* mise en place des grids */ 

.t1{
    grid-area: t1;
}
.im{
    grid-area: im;
}
.p1{
    grid-area: p1;
}
.l1{
    grid-area: l1;
}

.sect__grid4{
    opacity: 1;
    transform: scale(0.01);
    transition: 0.6s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin: 0 20px;
    padding: 200px 0 0 0 ;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 214px 120px;
    column-gap: 16px;
    row-gap: 62px;
    grid-template-areas: 
    "t1 t1 t1 t1  .  .  .  . "
    "p1 p1 p1 p1 p1  .  .  . ";
  /*  margin: 0 80px;
    padding: 200px 0 0 0 ;
    height: 90vh;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 214px 120px;
    column-gap: 20px;
    row-gap: 124px;
    grid-template-areas: 
    "t1 t1 t1 t1  .  .  .  .  .  . "
    ".  .  p1 p1 p1 p1 p1  .  .  . ";*/
}

@media(min-width:600px){
    .sect{
        &__list{
            display: flex;
            flex-direction: row;
        }
        &--col{
            display: flex;
            flex-direction: column;
            
        }
    }
    
    .sect__grid4{
        opacity: 1;
        transform: scale(0.01);
        transition: 0.6s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        margin: 0 64px;
        padding: 200px 0 0 0 ;
        height: 90vh;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 214px 120px;
        column-gap: 16px;
        row-gap: 74px;
        grid-template-areas: 
        "t1 t1 t1 t1  .  .  .  .  .  . "
        ".  .  p1 p1 p1 p1 p1  .  .  . ";
      /*  margin: 0 80px;
        padding: 200px 0 0 0 ;
        height: 90vh;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: 214px 120px;
        column-gap: 20px;
        row-gap: 124px;
        grid-template-areas: 
        "t1 t1 t1 t1  .  .  .  .  .  . "
        ".  .  p1 p1 p1 p1 p1  .  .  . ";*/
    }
}
@media(min-width:1024px){
    .sect{
        &--col{
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: auto;
            column-gap: 16px;
            row-gap: 62px;
            grid-template-areas: 
            "b1 b1 b1 b1 b2 b2 b2 b2 "
            "b3 b3 b3 b3 b4 b4 b4 b4 "
            "b5 b5 b5 b5 b6 b6 b6 b6 "
            "b7 b7 b7 b7 b8 b8 b8 b8 ";
        }
    }
    .sect__grid4{
        opacity: 1;
        transform: scale(0.01);
        transition: 0.6s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        display: block;
        margin: 0 80px;
        padding: 200px 0 0 0 ;
        height: 90vh;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: 214px 120px;
        column-gap: 20px;
        row-gap: 124px;
        grid-template-areas: 
        "t1 t1 t1 t1  .  .  .  .  .  . "
        ".  .  p1 p1 p1 p1 p1  .  .  . ";
      /*  margin: 0 80px;
        padding: 200px 0 0 0 ;
        height: 90vh;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: 214px 120px;
        column-gap: 20px;
        row-gap: 124px;
        grid-template-areas: 
        "t1 t1 t1 t1  .  .  .  .  .  . "
        ".  .  p1 p1 p1 p1 p1  .  .  . ";*/
    }
}