/* mise en place des fonts */ 

@font-face {
    font-family: "Neutro";
    src: url("/assets/font/Neutro.otf");
  }

  .head{
    &__lien{
        font-family: 'Roboto Mono', monospace;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 13%;
    }
    &__navLien{
        font-family: 'Roboto Mono', monospace;
    }
}
.sect{
    &__titre{
        font-family: "Neutro", sans-sérif;
        line-height: 100%;
        color: #ffffff;
        &--br{
            display: block;
            line-height: 130%;
        }
        &--1{
            font-size: 36px;
            line-height: 41px;
            margin: 0;
        }
        &--2{
            font-size: 26px;
        }
        &--3{
            font-size: 18px;
        }
        &--red{
            text-transform: uppercase;
            color:#FF0000;
        }
        &--outline{
            -webkit-text-stroke: 1px #FFFFFF;
            color: #121212;
        }
    }
    &__txt{
        color: #fffffa70;
        font-family: "Neutro", sans-sérif;
        font-size: 9;
    }
    &__txt2{
        font-family: 'Roboto Mono', monospace;
        font-weight: 300;
        color: #fff;
        font-size: 16px;
        line-height: 190%;
        width: 32ch;
        &--big{
            line-height: 150%;
            font-size: 20px;
            width: 30ch;
        }
    }
}
.foot{
    &__lien{
        font-family: 'Roboto Mono', monospace;
        color: #fff;
        text-decoration: none;
        font-weight: 300;
        text-align: right;
        font-size: 14px;
    }
}

@media(min-width:600px){
    .head{
        &__lien{
            font-size: 18px;
            font-weight: 900;
        }
    }
    .sect{
        &__titre{
            &--1{
                font-size: 66px;
                line-height: 66px;
                margin: 0;
            }
            &--2{
                font-size: 36px;
            }
            &--3{
                font-size: 24px;
            }
        }
        
        &__txt2{
            font-size: 16px;
            line-height: 190%;
            width: 40ch;
            &--big{
                font-size: 20px;
                line-height: 150%;
                width: 30ch;
            }
        }
    }
    .foot{
        &__lien{
            font-size: 16px;
        }
    }
}

@media (min-width:1024px){
    .head{
        &__lien{
            font-size: 22px;
        }
    }
    .sect{
        &__titre{
            &--1{
                font-size: 105px;
                line-height: 105px;
                margin: 0;
            }
            &--2{
                font-size: 66px;
            }
            &--3{
                font-size: 24px;
            }
        }
        &__txt2{
            font-size: 20px;
            line-height: 195%;
            width: 50ch;
            &--big{
                font-size: 36px;
                line-height: 150%;
                width: 30ch;
            }
        }
    }
    .foot{
        &__lien{
            font-size: 18px;
        }
    }
}

